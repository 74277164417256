@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Cinzel');

* {
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}

body {
  background-color: black;
  overflow: hidden;
  overscroll-behavior: none;
}

:root {
  --flexFont: clamp(0.75rem, 1.9vw, 1.05rem);
}

h1 {
  font-family: 'Montserrat';
  text-align: left;
  padding-left: 15px;
  font-weight: normal;
  margin-bottom: 10px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  transition: transform 0.3s;
  box-shadow: 0 0 8px #0000003f;
}

#nav {
  color: white;
}

.slideLeft {
  transform: translateX(120px);
}

.sideMenu {
  width: 120px;
  left: -120px;
  top: 0;
  height: auto;
  padding: 20px;
  position: absolute;
  height: 100%;
  background-color: #eaeaea;
  z-index: -1;
  display: none;
}

ul {
  list-style: none;
  font-family: 'Montserrat';
  display: flex;
  column-gap: clamp(22px, 5vw, 40px);
  justify-content: center;
  row-gap: 20px;
  font-size: clamp(8px, 2.5vw, 0.8rem);
  transition: opacity 0.5s;
}

ul li {
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
}

ul li::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid;
  width: 0;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s;
}

ul li:hover::after {
  width: 100%;
}

.sideMenu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  color: black;
  font-size: small;
  font-family: 'Montserrat';
  font-size: clamp(8px, 2.5vw, 0.8rem);
}

.sideMenu li {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 90px;
  white-space: wrap;
}

.page {
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
}

.overPage {
  width: 100vw;
  height: calc(100vh - 90px);
  height: calc(100svh - 90px);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#abouts {
  position: relative;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(14px); */
  z-index: 1;
  bottom: 0;
  /* transition: opacity 2.5s; */
}

.aboutTexts {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 100px)
}

.aboutTexts p {
  font-family: 'Montserrat';
  color: white;
  position: absolute;
  width: clamp(30ch, 95%, 70ch);
  text-align: justify;
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  padding: 40px 50px;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

.page::-webkit-scrollbar {
  display: none;
}

.section {
  text-align: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;
  overflow: hidden;
  overflow-y: auto;
}

.section::-webkit-scrollbar {
  display: none;
}

.collection {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: 100svh;
  overflow: hidden;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;

  /* backdrop-filter: blur(3px); */
}

.darkBackdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  background-color: #00000066;
  z-index: 2;
}

.textSection {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: clamp(0px, calc(100% - 20px), 700px);
  transition: color 0.5s;
}

.textSection:hover + .backdrop {
  opacity: 0.75;
}

.textCont {
  width: 100%;
  height: 100%;
  mix-blend-mode: difference;
}

.textSection h1 {
  text-transform: uppercase;
  font-size: clamp(1.3rem, 4vw, 3rem);
  padding-left: 18px;
}

.textSection p {
  font-family: 'Montserrat';
  padding-left: 20px;
  font-size: clamp(0.7rem, 2vw, 1.1rem);
  width: clamp(0px, calc(100% - 20px), 700px);
  text-align: justify;
}

.hide {
  opacity: 0;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Optional: Set a background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.images {
  width: 100%;
  height: 100ch;
  z-index: -1;
  overflow: hidden;
  display: inline;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.image-container {
  transition: opacity 1s ease;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgCont {
  height: 100vh;
  height: 100svh;
  width: 100%;
  position: relative;
}

.collectionCont {
  height: 100vh;
  height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.arrows {
  position: absolute;
  top: calc(50% - 50px);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: clamp(10px, 3vw, 20px);
  user-select: none;
  -webkit-user-select: none;
}

.cImgCont {
  min-height: 100vh;
  min-height: 100svh;
  width: 100%;
  display: flex;
  transition: transform 0.5s;
}

.cImgCont::-webkit-scrollbar {
  display: none;
}

.cImg {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.cImg img {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
  /* animation: none; */
}

.animatedImg {
  animation: slowbounce 18s linear infinite;
}

.imgCont img {
  position: absolute;
  width: 100%;
}

.navbar {
  width: 100vw;
  height: 90px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 50px;
  justify-content: space-between;
  z-index: 3;
  transition: color 0.6s;
}

.logo {
  text-align: center;
  font-family: 'Montserrat';
  display: flex;
  z-index: 1;
  /* column-gap: 28px; */
  align-items: center;
  /* height: 40px; */
  transition: opacity 0.6s;
}

.homeBlur {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.25) 5%, transparent 20%);
  transition: all 0.4s;
}

.logo img {
  height: 45%;
  transition: filter 0.5s;
}

.blackImg {
  filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(66deg) brightness(106%) contrast(100%);
}

.logoText {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-left: clamp(9px, 1vw, 14px);
  max-width: auto;
}

.logoSlide {
  max-width: 0;
  margin-left: 0px;
  animation: slideText 1.3s ease 1s 1 forwards;
}

@keyframes slideLine {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
    margin-left: clamp(10px, 1vw, 14px);
  }
}

@keyframes navSlide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideText {
  0% {
    max-width: 0;
  }
  90% {
    max-width: 20ch;
    margin-left: clamp(9px, 1vw, 14px);
  }
  100% {
    margin-left: clamp(9px, 1vw, 14px);
    max-width: 25ch;
  }
}

.logoLine {
  margin-left: clamp(9px, 1vw, 14px);
  width: 2px;
  border-left: 2px solid;
  height: 90%;
  transition: opacity 0.5s;
}

.lineSlide {
  margin-left: 0px;
  opacity: 0;
  width: 0;
  animation: slideLine 0.5s ease 0.5s forwards;
}

.logo h3 {
  font-weight: normal;
  margin: 0;
  text-transform: uppercase;
  font-family: 'Cinzel';
  white-space: nowrap;
}

.logo span {
  margin: 0;
  letter-spacing: 8px;
}

.menuButton {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px; 
  font-size: clamp(12px, 2vw, 1rem);
}

.loading {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-top: 2px solid white;
  border-right: 2px solid white;
  animation: rotate 0.7s linear infinite;
}

.reciept {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  font-family: 'Montserrat';
  z-index: 2;
}

.reciept h1 {
  padding: 0;
}

.failMessage {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  width: 60ch;
  text-align: justify;
  z-index: 2;
}

.failMessage p {
  margin-bottom: 5px;
}

.failMessage h1 {
  padding: 0;
}

.failMessage button {
  margin-top: 15px;
  background-color: #4788c8;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.centerCont {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.timepicker {
  position: relative;
  z-index: 4;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px #00000033;
  border-radius: 10px;
  width: clamp(300px, 90vw, 700px);
  margin: 10px 0px;
  padding: 10px 0px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  background-color: white;
}

.dialog span {
  text-align: left;
  width: 94%;
  display: block;
  font-family: 'Montserrat';
  padding: 5px;
  margin: 2px 0px;
  font-size: var(--flexFont);
}

.dialog input {
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  height: 30px;
  width: 93%;
  margin: 0px 0 15px 0;
  padding-left: 8px;
  font-family: 'Montserrat';
}

.smallButton {
  border-radius: 6px;
  background-color: #e6e6e6;
  padding: 4px;
  min-width: 60px;
  font-family: 'Montserrat';
  color: #454545;
  border: none;
  align-self: flex-start;
  margin: 8px 0 16px 2.5%;
  cursor: pointer;
}

.smallMsg {
  text-align: left;
  width: 94%;
  display: block;
  font-family: 'Montserrat';
  padding: 5px;
  margin: 0 0 10px 0;
  color: #515151;
}

.errorMsg {
  text-align: left;
  width: 94%;
  padding: 5px;
  margin: -5px 0 0 0;
  font-size: 0.8rem;
  color: #e80000;
  display: none;
}

.timeslots {
  margin: 5px 0 15px 0;
  display: grid;
  width: 94%;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 5px;
  justify-content: center;
}

.slot {
  border-radius: 6px;
  background-color: #e6e6e6;
  padding: 4px;
  font-family: 'Montserrat';
  color: #454545;
  text-align: center;
  cursor: pointer;
}

.requestCallButton {
  font-family: 'Montserrat';
  background-color: #5697d7;
  width: 94%;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 7px;
  margin: 10px 0;
  font-size: 1.06rem;
  box-shadow: 0 0 10px #00000033;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.selected {
  background-color: #c0ccd9;
}

.appointmentsCont {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  z-index: 3;
  position: relative;
}

a {
  text-decoration: none;
  color: #2f2f2f;
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}

.viewBookings {
  width: clamp(300px, 90vw, 850px);
  border-collapse: separate;
  border-spacing: 10px;
}

tr {
  border-radius: 6px;
  font-family: 'Montserrat';
  color: #454545;
  text-align: center;
  width: 95%;
  box-shadow: 0 0 5px #00000044;
  min-height: 50px;
}

td {
  padding: 10px clamp(7px, 1.5vw, 10px);
  font-size: var(--flexFont);
}

.apptButton {
  cursor: pointer;
}

#emailNotif {
  position: absolute;
  left: 15px;
  bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 0 15px #0000005c;
  padding: 15px;
  opacity: 0;
  background-color: white;
  z-index: 4;
}

#deletePrompt {
  position: absolute;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: #00000055;
  backdrop-filter: blur(5px);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.deleteBox {
  border-radius: 5px;
  box-shadow: 0 0 5px #00000099;
  width: clamp(300px, 90vw, 500px);
  background-color: white;
  padding: 30px 15px;
  text-align: center;
  font-family: 'Montserrat';
}

.buttonrow {
  margin-top: 40px;
  display: flex;
  column-gap: 5px;
  width: 100%;
  justify-content: space-around;
}

.buttonrow button {
  cursor: pointer;
}

.btnDel {
  background-color: #772525;
  color: #fff;
}

.buttonrow button {
  padding: 8px 30px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'Montserrat';
}

.vidCont {
  width: 100vh;
  height: 100vh;
  height: 100svh;
}

.centerLogo {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  row-gap: 50px;
  flex-direction: column;
  transition: opacity 0.3s;
}

.hideCLogo {
  opacity: 0;
}

.loginBox {
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #000000cc;
  row-gap: 10px;
  font-family: 'Montserrat';
}

.loginBox input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #00000033;
}

.loginBox button {
  font-family: 'Montserrat';
  margin-top: 15px;
  background-color: #1087ff;
  color: white;
  padding: 5px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.bookingsRefresh {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: end;
  font-family: 'Montserrat';
  padding: 20px 15px;
}

.ellipse {
  width: 3px;
  height: 3px;
  margin: 0 2px;
  background-color: #666;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}
#elips0 {
  animation-delay: 0s;
}

#elips1 {
  animation-delay: 0.2s;
}

#elips2 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
      /* opacity: 1; */
  }
  50% {
      transform: translateY(-5px);
      /* opacity: 0; */
  }
}

@keyframes slowbounce {
  0%, 100% {
    top: 50%
    /* transform: translateY(0); */
}
50% {
  top: 45%;
    /* transform: translateY(-50px); */
}
}

/* CALENDER */

.react-calendar {
  width: clamp(300px, 50vw, 450px);
  max-width: 100%;
  background: white;
  box-shadow: 0 0 10px #00000055;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 7px;
  padding-bottom: 5px;
  overflow: hidden;
  z-index: 4;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  color: #2f2f2f;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #2f2f2f;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day {
  color: #2f2f2f;
}

.react-calendar__month-view__days__day--weekend {
  color: #838383;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 0px;
  padding: 13px 0px;

}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #c1ced4;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #9ac0d2;
}

.react-calendar__tile--hasActive {
  background: #566e86;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bgAnim {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.blob {
  position: absolute;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  /* box-shadow: */
  /* 0 0 23px 3px #fff,  inner white */
  /* 0 0 18px 3px rgb(255, 134, 255), middle magenta */
  /* 0 0 25px 3px rgb(128, 255, 255); */
  scale: 0.15;
  opacity: 0.9;
}

@media only screen and (max-width: 900px) {
  .sideMenu {
    display: block;
  }

  .navbar ul {
    display: none;
  }

  .navbar {
    justify-content: center;
  }

  .menuButton {
    display: flex;;
  }

  .cImg {
    height: calc(100% + 200px);
    width: auto;
  }

  .page {
    overflow-y: auto;
  }
}
